import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Commento from "react-commento"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ColorWrapper from "../components/ColorWrapper"

const Header = styled.header`
  margin-top: 1rem;
  margin-bottom: 2rem;
`

const Date = styled.p`
  color: var(--tertiary-text);
  font-size: 0.9rem;
`

const Title = styled.h1`
  margin-bottom: 0.5rem;
  font-size: 2rem;
`

const Section = styled.section`
  line-height: 1.7rem;

  img {
    display: block;
    margin: auto;
  }
`

const LinkList = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
`

const CommentsWrapper = styled.div`
  margin-top: 1rem;
`

const BlogPostTemplate = ({ data, pageContext }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  return (
    <Layout title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <Header>
          <Title>{post.frontmatter.title}</Title>
          <Date>{post.frontmatter.date}</Date>
        </Header>
        <Section dangerouslySetInnerHTML={{ __html: post.html }} />
      </article>

      <nav>
        <LinkList>
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                <ColorWrapper>← {previous.frontmatter.title}</ColorWrapper>
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                <ColorWrapper right>{next.frontmatter.title} →</ColorWrapper>
              </Link>
            )}
          </li>
        </LinkList>
      </nav>

      <CommentsWrapper>
        <Commento id={post.id} />
      </CommentsWrapper>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "Do MMMM, YYYY", locale: "en-GB")
        description
      }
    }
  }
`
